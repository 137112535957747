import { hasStepEditableFields } from 'features/modular-workflow/runner/hasStepEditableFields';
import { useModulareWorkflowRunnerStore } from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';

export const useCalculateVisibleSteps = (
  currentWorkflowStepIndex: number
): [visibleStepIndex: number, amountVisibleSteps: number] => {
  const workflow = useModulareWorkflowRunnerStore(state => state.workflow);
  if (!workflow || !workflow.steps || workflow.steps.length === 0) {
    return [0, 1];
  }

  // Steps which don't have any editable inputs are not visible
  const visibleStepCount = workflow.steps.filter(hasStepEditableFields).length;

  // Calculate the visible step index
  let visibleStepIndex = 0;
  for (let i = 0; i <= currentWorkflowStepIndex; i++) {
    if (hasStepEditableFields(workflow.steps[i])) {
      visibleStepIndex++;
    }
  }

  return [visibleStepIndex - 1, visibleStepCount];
};
