import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
} & IContentLoaderProps;

export const ConditionalContentLoader = ({
  isLoading,
  height,
  width,
  viewBox,
  children
}: Props) => {
  if (isLoading) {
    return (
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={viewBox}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        {children}
      </ContentLoader>
    );
  }

  return null;
};
