import { Checkbox, FormControlLabel } from '@mui/material';
import { BaseSetting } from 'features/modular-workflow/runner/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/runner/settings/InputRenderer';
import { useContext } from 'react';

export const DataTypeBoolean = () => {
  const { input, onChange, inputErrorList } = useContext(InputRendererContext);

  return (
    <BaseSetting>
      {elementId => (
        <FormControlLabel
          control={
            <Checkbox
              id={elementId}
              placeholder={input.description}
              defaultChecked={input.value as boolean}
              onChange={(_event, checked) => onChange(input, checked)}
            />
          }
          label="Default checked"
          color={inputErrorList.length > 0 ? 'error' : undefined}
        />
      )}
    </BaseSetting>
  );
};
