import { Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import React from 'react';
import styled from 'styled-components';

type Props = {
  title: React.ReactNode;
  subtitle?: string;
  extra?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactElement;
};

const TesterSection = ({ title, subtitle, actions, extra, children }: Props) => (
  <Root>
    <Header>
      <FlexContainer gap="small" direction="row" alignItems="flex-end">
        <Title variant="subtitle1">{title}</Title>
        {subtitle && <Subtitle variant="overline">{subtitle}</Subtitle>}
      </FlexContainer>
      <Addon>
        {extra && <Extra>{extra}</Extra>}
        <Actions>{actions}</Actions>
      </Addon>
    </Header>
    <Content>{children}</Content>
  </Root>
);

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 36px;
`;

const Title = styled(Typography)`
  && {
    font-weight: 500;
  }
`;

const Subtitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackMediumEmphasis};
  }
`;

const Addon = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xlarge};
`;

const Extra = styled.div``;
const Actions = styled.div`
  display: flex;
  align-items: center;
`;
const Content = styled.div``;

export default TesterSection;
