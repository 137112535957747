import { Switch } from '@mui/material';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { useAppThemeStore, useIsDarkMode } from 'styles/new-theme/useAppThemeStore';

type Props = {
  enabled: boolean;
};
export const DarkModeToggleItem = (props: Props) => {
  const isDarkMode = useIsDarkMode();
  const setThemeMode = useAppThemeStore(state => state.setThemeMode);

  const handleToggle = (event: SyntheticEvent) => {
    event.preventDefault();

    const newThemeMode = isDarkMode ? 'light' : 'dark';
    setThemeMode(newThemeMode);

    if (newThemeMode === 'dark') {
      GAEvents.darkModeEnabled();
    } else {
      GAEvents.darkModeDisabled();
    }

    // Some parts of (custom) styled components doesn't refresh correctly
    // This is a hack to force refresh
    location.reload();
  };

  if (!props.enabled) {
    return null;
  }

  return (
    <MenuDropdownItem
      gtmId={gtmIds.sidebar.accountMenuItem}
      icon={<Switch size="small" checked={isDarkMode} onChange={handleToggle} />}
      onClick={handleToggle}
    >
      <FormattedMessage id="layout.sidebar.actions.profile.dark_mode" />
    </MenuDropdownItem>
  );
};
