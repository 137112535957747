import { Chip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { FormattedPricing } from 'features/pricing/FormattedPricing';
import { Discount, isTypeBusinessPlan } from 'features/pricing/types';
import { ReactNode } from 'react';
import { ACLPricing } from 'services/api/customer/types';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';
import useTr from 'utils/hooks/useTr';

type Props = {
  pricing: ACLPricing;
  discount?: Discount;
  selected?: boolean;
  name?: string;
  onClick?: (pricing: ACLPricing) => void;
};

export const PricingPlanButton = ({ pricing, discount, selected, name, onClick }: Props) => {
  const translate = useTr();

  return (
    <Root
      $selected={selected}
      onClick={() => onClick?.(pricing)}
      clickable={!!onClick}
      type="button"
    >
      <Content>
        <FlexContainer direction="row" gap="three" alignItems="center">
          <Typography variant="h6">{name ? name : pricing.name}</Typography>

          {pricing.isTrial && (
            <FreeTrialBadge
              label={translate('register.pricing_plan_step.free_trial.badge')}
              size="small"
            />
          )}
        </FlexContainer>
        <Typography variant="h6">
          <FormattedPricing pricing={pricing} discount={discount} />
        </Typography>
      </Content>

      <Description variant="body2">{selectPlanDescription(pricing)}</Description>
    </Root>
  );
};

function selectPlanDescription(pricing: ACLPricing) {
  const isBusinessPlan = isTypeBusinessPlan(pricing);

  const translationPricingTypeName = isBusinessPlan ? 'business' : pricing.type;

  return (
    <FormattedMessage
      id={`pricing.plan.${translationPricingTypeName}.description`}
      values={{
        b: (chunks: ReactNode) => <b>{chunks}</b>
      }}
    />
  );
}

const Root = styled.button<{ $selected?: boolean; clickable?: boolean }>`
  ${resetButton};

  padding: ${({ theme }) => theme.spacings.medium};

  background-color: ${({ theme, $selected }) =>
    $selected ? theme.colors.primaryColorSelected : theme.colors.backgroundPaperElevation2};
  border-radius: ${({ theme }) => theme.borderRadius.two};
  cursor: ${({ $selected, clickable }) => (clickable && !$selected ? 'pointer' : 'auto')};
  transition: ${({ theme }) => `background-color ${theme.transition.default}`};

  &:hover {
    background-color: ${({ theme, $selected }) =>
      $selected
        ? undefined
        : theme.mode === 'dark'
        ? theme.colors.backgroundPaperElevation6
        : theme.colors.palette.dark__100};
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: ${({ theme }) => theme.spacings.xsmall};
`;

const Description = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.blackHighEmphasis};
    text-align: left;
  }
`;

export const FreeTrialBadge = styled(Chip)`
  background-color: ${({ theme }) => theme.colors.warningMain};
  color: ${({ theme }) => theme.colors.commonWhiteMain};
  &:hover {
    background-color: ${({ theme }) => theme.colors.warningMain};
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.commonWhiteMain};
  }
`;
