import { FormGroup, Link } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import { WarningHint } from 'components/profile/components/pricing-plan-card/WarningHint';
import { useFetchImagesLimitations } from 'features/aiWriter/AiWriterSidebar/steps/images/ImagesRequestsIndicator/useFetchImagesLimitations';
import { useFetchSeoLimitations } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/useFetchSeoLimitations';
import { useShowRunOutOfCreditsModal } from 'features/pricing/modals/FreeCreditsLimitReachedModal';
import {
  getPricingPlan,
  getSeoLimitations,
  getUnsplashImagesLimitations
} from 'features/pricing/store/selectors';
import { Step3MultimediaSection } from 'features/workflowBlogPostBuilder/steps/Step3MultimediaSection';
import { Step3ReferencesSection } from 'features/workflowBlogPostBuilder/steps/Step3ReferencesSection';
import { Step3SeoSection } from 'features/workflowBlogPostBuilder/steps/Step3SeoSection';
import { FormValues } from 'features/workflowBlogPostBuilder/types';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

import * as CommonStyled from './common.styles';

const allElementsArray = [
  'images',
  'youtube_video',
  'seobility',
  'what_people_asked',
  'external_links'
];

type Props = { onGoToKeywordsClick: () => void };

export const Step3 = ({ onGoToKeywordsClick }: Props) => {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const showRunOutOfCreditsModal = useShowRunOutOfCreditsModal();

  const pricingPlan = useAppSelector(getPricingPlan);

  const isUserOnFreePlan = ['free'].includes(pricingPlan);
  const isOnPlanBelowPro = ['starter', 'standard'].includes(pricingPlan);
  const [showProWarning, setShowProWarning] = useState(true);

  useFetchImagesLimitations();

  const imagesLimitations = useAppSelector(getUnsplashImagesLimitations);
  const isImagesOutOfCredits =
    imagesLimitations &&
    !imagesLimitations.isUnlimited &&
    imagesLimitations?.used >= imagesLimitations?.limit;

  useFetchSeoLimitations();
  const seoLimitations = useAppSelector(getSeoLimitations);

  const isSeoOutOfCredits =
    seoLimitations && !seoLimitations.isUnlimited && seoLimitations?.used >= seoLimitations?.limit;

  const isLimitedFeature = isImagesOutOfCredits || isSeoOutOfCredits;

  const isKeywordsNotProvided = values.keywords.length === 0;

  useEffect(() => {
    if (!isSeoOutOfCredits && !isLimitedFeature && !isKeywordsNotProvided && !isOnPlanBelowPro) {
      setFieldValue('elements', allElementsArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderHint() {
    if (isKeywordsNotProvided) {
      return (
        <WarningHint color="primary100">
          <ColoredTypography>
            <FormattedMessage
              id="blog_post_builder.steps.elements.no_keywords_hint"
              values={{
                button: (text: string) => (
                  <BackLink onClick={onGoToKeywordsClick} type="button">
                    {text}
                  </BackLink>
                ),
                br: <br />
              }}
            />
          </ColoredTypography>
        </WarningHint>
      );
    }
    if (isLimitedFeature) {
      return (
        <WarningHint>
          <ColoredTypography color="warningRed">
            <FormattedMessage
              id="blog_post_builder.steps.elements.limit_hit_hint"
              values={{
                button: (text: string) => (
                  <UpgradeButton onClick={showRunOutOfCreditsModal}>{text}</UpgradeButton>
                )
              }}
            />
          </ColoredTypography>
        </WarningHint>
      );
    }
    if (isUserOnFreePlan && showProWarning) {
      return (
        <WarningHint
          color="primary100"
          onClose={() => setShowProWarning(false)}
          icon={<YellowCrownImageBox />}
        >
          <FormattedMessage
            id="blog_post_builder.steps.elements.upgrade_hint"
            values={{
              button: (text: string) => (
                <UpgradeText onClick={showRunOutOfCreditsModal}>{text}</UpgradeText>
              ),
              b: (text: string) => <b>{text}</b>
            }}
          />
        </WarningHint>
      );
    }
  }

  return (
    <>
      {renderHint()}

      <CommonStyled.Title>
        <FormattedMessage id="blog_post_builder.steps.elements.title" />
      </CommonStyled.Title>

      <Step3MultimediaSection
        imagesDisabled={isImagesOutOfCredits}
        videoDisabled={isKeywordsNotProvided}
        videoUpgradeRedirect={!isKeywordsNotProvided && isOnPlanBelowPro}
        isFreePlan={isUserOnFreePlan || isOnPlanBelowPro}
      />

      <Step3SeoSection
        featureDisabled={isKeywordsNotProvided}
        seoDisabled={isKeywordsNotProvided}
        seoUpgradeRedirect={!isKeywordsNotProvided && (isSeoOutOfCredits || isOnPlanBelowPro)}
        isFreePlan={isUserOnFreePlan || isOnPlanBelowPro}
      />

      <Step3ReferencesSection
        isFeatureDisabled={isKeywordsNotProvided}
        upgradeRedirect={!isKeywordsNotProvided && isOnPlanBelowPro}
        isFreePlan={isUserOnFreePlan || isOnPlanBelowPro}
      />
    </>
  );
};

const UpgradeButton = styled.button`
  background: none;
  text-decoration: underline;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.warningRed};
`;

const UpgradeText = styled.button`
  background: none;
  text-decoration: underline;
  border: none;
  padding: 0;
  font-size: 1rem;
  color: #444444;
`;

const BackLink = styled(Link)`
  cursor: pointer;
`;

export const ProLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const CheckboxGroup = styled(FormGroup)`
  margin-left: ${({ theme }) => theme.spacings.medium};
`;

export const YellowCrownImageBox = styled(YellowCrownImage)`
  width: 50px;
  height: 25px;
`;
