import { Stack, TextField } from '@mui/material';
import { BaseSetting } from 'features/modular-workflow/runner/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/runner/settings/InputRenderer';
import { PlaceholderList } from 'features/modular-workflow/runner/settings/PlaceholderList';
import { useModulareWorkflowRunnerStore } from 'features/modular-workflow/runner/useModulareWorkflowRunnerStore';
import { useContext, useRef, useState } from 'react';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export const DataTypeLargeText = () => {
  const { input, stepIndex, onChange, inputErrorList } = useContext(InputRendererContext);
  const workflow = useModulareWorkflowRunnerStore(state => state.workflow);

  const inputRef = useRef<HTMLDivElement>(null);
  const [localValue, setLocalValue] = useState(input.value || '');

  assertNonNullable(workflow, 'workflow must be loaded first');

  const previousSteps = workflow.steps.slice(0, stepIndex);
  const outputsFromPreviousSteps = previousSteps.flatMap(step => step.outputs);

  const handleChange = (value: string) => {
    setLocalValue(value);
    onChange(input, value);
  };

  const handlePlaceholderClick = (value: string) => {
    const newValue = `${localValue} {{${value}}}`;

    setLocalValue(`${localValue} {{${value}}}`);
    onChange(input, newValue);

    // Focus input after adding placeholder
    inputRef.current?.focus();
  };

  return (
    <BaseSetting>
      {elementId => (
        <Stack spacing={1}>
          <TextField
            ref={inputRef}
            id={elementId}
            placeholder={input.description}
            variant="outlined"
            fullWidth={true}
            multiline={true}
            minRows={3}
            maxRows={8}
            value={localValue}
            error={inputErrorList.length > 0}
            helperText={inputErrorList.map(error => error.errorMessage).join(', ')}
            onChange={event => handleChange(event.target.value)}
          />
          <PlaceholderList
            outputs={outputsFromPreviousSteps}
            onPlaceholderClick={handlePlaceholderClick}
          />
        </Stack>
      )}
    </BaseSetting>
  );
};
