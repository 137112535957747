import { ConditionalContentLoader } from 'components/base/ConditionalContentLoader';

type Props = {
  isLoading: boolean;
};

const countRectYOffset = (index: number) => index * 52 + 12;
const countCircleYOffset = (index: number) => index * 52 + 28;

export const SubjectLinesSuggestionsLoader = ({ isLoading }: Props) => (
  <ConditionalContentLoader width="100%" height="300" isLoading={isLoading}>
    {[0, 1, 2, 3, 4].map(index => (
      <>
        <circle cx="32" cy={countCircleYOffset(index)} r="16" />
        <rect x="76" y={countRectYOffset(index)} rx="3" ry="9" width="80%" height="32" />
      </>
    ))}
  </ConditionalContentLoader>
);
