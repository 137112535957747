import { Stack, TextField } from '@mui/material';
import { BaseSetting } from 'features/modular-workflow/builder/settings/BaseSetting';
import { InputRendererContext } from 'features/modular-workflow/builder/settings/InputRenderer';
import { PlaceholderList } from 'features/modular-workflow/builder/settings/PlaceholderList';
import { BuilderModularWorkflowStepInputWithValue } from 'features/modular-workflow/builder/types-builder';
import { useModulareWorkflowCreationStore } from 'features/modular-workflow/builder/useModulareWorkflowCreationStore';
import { useContext, useRef, useState } from 'react';

export const DataTypeConfigurableText = () => {
  const {
    input,
    inputSettings: settings,
    stepIndex,
    onChange,
    inputErrorList
  } = useContext(InputRendererContext);
  const workflow = useModulareWorkflowCreationStore(state => state.workflow);

  const typedInput = input as BuilderModularWorkflowStepInputWithValue;

  const inputRef = useRef<HTMLDivElement>(null);
  const [localValue, setLocalValue] = useState(typedInput.value || '');

  const previousSteps = workflow.steps.slice(0, stepIndex);
  const outputsFromPreviousSteps = previousSteps.flatMap(step => step.outputs);

  const handleChange = (value: string) => {
    setLocalValue(value);
    onChange(typedInput, value);
  };

  const handlePlaceholderClick = (value: string) => {
    const newValue = `${localValue} {{${value}}}`;

    setLocalValue(`${localValue} {{${value}}}`);
    onChange(typedInput, newValue);

    // Focus input after adding placeholder
    inputRef.current?.focus();
  };

  return (
    <BaseSetting>
      {elementId => (
        <Stack spacing={1}>
          <TextField
            ref={inputRef}
            id={elementId}
            placeholder={settings.description}
            variant="outlined"
            fullWidth={true}
            multiline={true}
            minRows={3}
            maxRows={8}
            value={localValue}
            onChange={event => handleChange(event.target.value)}
            error={inputErrorList.length > 0}
            helperText={inputErrorList.map(error => error.errorMessage).join(', ')}
          />
          <PlaceholderList
            outputs={outputsFromPreviousSteps}
            onPlaceholderClick={handlePlaceholderClick}
          />
        </Stack>
      )}
    </BaseSetting>
  );
};
