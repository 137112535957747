import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { useGetThemeConfig } from 'styles/new-theme/useAppThemeStore';

export const WorkflowButtonContentLoader = () => {
  const theme = useGetThemeConfig();

  return (
    <Root
      viewBox="0 0 310 122"
      speed={2}
      backgroundColor={theme.colors.palette.dark__200}
      foregroundColor={theme.colors.backgroundPaperElevation2}
    >
      <circle cx="53" cy="61" r="28" />
      <rect x="100" y="34" rx="4" ry="4" width="100" height="13" />
      <rect x="100" y="60" rx="3" ry="3" width="170" height="10" />
      <rect x="100" y="75" rx="3" ry="3" width="150" height="10" />
    </Root>
  );
};

const Root = styled(ContentLoader)`
  border-radius: ${({ theme }) => theme.borderRadius.one};
  border: 1px solid ${({ theme }) => theme.colors.componentsRatingEnabledborder};

  background-color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.backgroundPaperElevation2 : undefined};
`;
