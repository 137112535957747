import { Typography } from '@mui/material';
import AddFieldButton from 'features/aiTester/buttons/AddFieldButton';
import useCheckIfTextLimitIsAchieved from 'features/aiTester/hooks/useCheckIfTextLimitIsAchieved';
import BriefConfigButton from 'features/aiTester/modals/briefConfig/BriefConfigButton';
import GoalConfigButton from 'features/aiTester/modals/goalConfig/GoalConfigButton';
import { getIsGenerateConfigEditable, getTesterActiveTab } from 'features/aiTester/store/selectors';
import TesterContentField from 'features/aiTester/TesterContentField';
import TesterSection from 'features/aiTester/TesterSection';
import mkTesterTourClassName from 'features/aiTester/tour/utils/mkTesterTourClassName';
import { getGetEmbeddingModelLanguageAndCountryById } from 'features/embeddingModels/store/selectors';
import { useGetOutputLabel } from 'features/textGenerator/utils/useGetPromptsOthers';
import { createRef, useMemo } from 'react';
import FlipMove from 'react-flip-move';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const TesterTextsSection = () => {
  const tr = useTr();

  useCheckIfTextLimitIsAchieved();

  const { embeddingModelId, texts, generateTextConfig } = useAppSelector(getTesterActiveTab);
  const isGenerateConfigEditable = useAppSelector(getIsGenerateConfigEditable);
  const getEmbeddingModelLanguageAndCountryById = useAppSelector(
    getGetEmbeddingModelLanguageAndCountryById
  );
  const { language: modelLanguage, country: modelCountry } =
    getEmbeddingModelLanguageAndCountryById(embeddingModelId);
  const getOutputLabel = useGetOutputLabel(modelLanguage, modelCountry);

  const actions = (
    <>
      <GoalConfigButton />
      {isGenerateConfigEditable && <BriefConfigButton />}
    </>
  );

  const title = useMemo(() => {
    if (generateTextConfig?.outputType) {
      return getOutputLabel(generateTextConfig.outputType);
    }

    return tr('testing.my_selection');
  }, [generateTextConfig, getOutputLabel, tr]);

  return (
    <TesterSection title={title} actions={actions} subtitle={`${texts.length} /10`}>
      <Container>
        <Container>
          {texts.length > 0 ? (
            // @ts-expect-error Package doesn't yet define a children prop (React 18)
            <AnimatedList duration={750} className={mkTesterTourClassName('comparison', 7)}>
              {texts.map(value => (
                <TesterContentField key={value.id} value={value} ref={createRef()} />
              ))}
            </AnimatedList>
          ) : (
            <PlaceholderContainer>
              <Typography variant="subtitle2" color="textSecondary" align="center">
                <FormattedMessage id="testing.text_placeholder" />
              </Typography>
            </PlaceholderContainer>
          )}
        </Container>
        <div>
          <AddFieldButton />
        </div>
      </Container>
    </TesterSection>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

const AnimatedList = styled(FlipMove)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

const PlaceholderContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.three};
  background: ${({ theme }) => theme.colors.backgroundPaperElevation2};
  backdrop-filter: blur(0.25rem);
  border-radius: 0.25rem;
`;

export default TesterTextsSection;
