import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { Link, Typography } from '@mui/material';
import doodle from 'assets/home-doodle.svg';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import { getRoutePath } from 'config/routes';
import { CreateWorkflowButton } from 'features/aiWriter/AiWriterProjectOverview/CreateWorkflowButton';
import { getIsApiCustomer } from 'features/customer/store/selectors';
import { ApiDashboard } from 'features/homePage/ApiDashboard';
import { CarouselComponent } from 'features/homePage/Carousel';
import { CreateChatForm } from 'features/homePage/chatForm/CreateChatForm';
import { HomeBanner } from 'features/homePage/HomeBanner';
import { HomeFeatureCards } from 'features/homePage/HomeFeatureCards';
import { HomeRecentFiles } from 'features/homePage/HomeRecentFiles';
import { QuickActionCardProps, useQuickActions } from 'features/homePage/useQuickActions';
import { WorkflowButtonContentLoader } from 'features/homePage/WorkflowButtonContentLoader';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { chunk, range } from 'lodash';
import { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

const cardsPerSlide = 9;

export const HomePage = (): ReactElement => {
  const navigate = useNavigate();
  const isApiCustomer = useAppSelector(getIsApiCustomer);

  const { quickActions: allQuickActions, isLoading: isLoadingQuickActions } = useQuickActions();
  // We wanna show 9 quick actions per slide, so split the array by groups of 9
  const quickActionChunks = chunk(allQuickActions, cardsPerSlide);
  const remainingCardsOnFirstSlide =
    quickActionChunks.length === 0 ? cardsPerSlide : cardsPerSlide - quickActionChunks[0].length;

  useEnableSidebarOnMount();

  useNavigateToOnboarding();

  const handleViewAllClick = () => {
    navigate(getRoutePath('myContent'));
  };

  if (isApiCustomer) {
    return <ApiDashboard />;
  }

  return (
    <Container size="lg">
      <Doodle src={doodle} />
      <ContentContainer gap="four">
        <Title />

        <CreateChatForm />

        <FlexContainer gap="two">
          <SectionHeadline>
            <FormattedMessage id="home.sections.tools.title" />
          </SectionHeadline>
          <CarouselComponent>
            {quickActionChunks.map(quickActions => (
              <WorkflowButtons key={Math.random()}>
                {quickActions.map(
                  ({ name, title, subtitle, gtmId, onClick, icon }: QuickActionCardProps) => (
                    <CreateWorkflowButton
                      key={name}
                      title={title}
                      subtitle={subtitle}
                      gtmId={gtmId}
                      onClick={onClick}
                      icon={icon}
                    />
                  )
                )}

                {isLoadingQuickActions &&
                  remainingCardsOnFirstSlide > 0 &&
                  range(remainingCardsOnFirstSlide).map(() => (
                    <WorkflowButtonContentLoader key={Math.random()} />
                  ))}
              </WorkflowButtons>
            ))}
          </CarouselComponent>
        </FlexContainer>

        <HomeBanner />

        <FlexContainer gap="two">
          <FlexContainer direction="row" justifyContent="space-between">
            <SectionHeadline>
              <FormattedMessage id="home.sections.recentFiles.title" />
            </SectionHeadline>
            <ViewAllButton onClick={handleViewAllClick}>
              <FormattedMessage id="home.sections.recentFiles.button.viewAll" />
            </ViewAllButton>
          </FlexContainer>

          <HomeRecentFiles />
        </FlexContainer>

        <FlexContainer gap="two">
          <FlexContainer direction="row" gap="two" alignItems="baseline">
            <SectionHeadline>
              <FormattedMessage id="home.sections.premiumTools.title" />
            </SectionHeadline>
            <YellowCrownImage />
          </FlexContainer>
          <HomeFeatureCards />
        </FlexContainer>
      </ContentContainer>
    </Container>
  );
};

function Title() {
  return (
    <Root>
      <Typography variant="h4">
        <FormattedMessage id="home.title" />
      </Typography>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

function SectionHeadline({ children }: { children: ReactNode }) {
  return <Typography variant="subtitle1">{children}</Typography>;
}

const ViewAllButton = styled(Link)`
  color: ${({ theme }) => theme.colors.commonBlackMain};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  text-decoration-line: underline;
  :visited,
  :hover {
    color: ${({ theme }) => theme.colors.commonBlackMain};
  }
  cursor: pointer;
`;

const WorkflowButtons = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.three};
  grid-template-columns: repeat(3, 1fr);

  padding: ${({ theme }) => `0 ${theme.spacings.two}`};
`;

const ContentContainer = styled(FlexContainer)`
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
`;

const Doodle = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;
